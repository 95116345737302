<template lang="pug">
div
  v-row.justify-center
      p.text-center {{text ? text: "Erstelle Dein Profil, um passende Jobs zu finden:"}}
  v-row.justify-center
      v-btn.white--text(color="#FF5D00" depressed @click="startOnboarding") Profil erstellen
</template>

<script>
export default {
  props: ['text', 'onClick'],
  computed: {},
  methods: {
    startOnboarding() {
      this.$store.dispatch('user/markOnboardingStarted');
      this.$router.push({ name: 'Onboarding', query: { step: '1' } });
    },
  },
};
</script>
