<template lang="pug">
    div
      v-row.justify-center
          p.text-center {{text ? text: "Es gibt noch keine Informationen"}}
      v-row.justify-center
          AddPositionDialog(type="outlined")
              v-btn(color="orange" depressed outlined ) Stelle hinzufügen

      
        
</template>

<script>
import AddPositionDialog from '@/components/business/offering/positionDetails/AddPositionDialog';
export default {
  props: ['text', 'onClick'],
  components: { AddPositionDialog },
};
</script>
