<template lang="pug">
v-card
    NotAvailableOverlay(v-if="match.opponentRemoved" :canDelete="!match.archived" @click="archiveMatch(true)")
    v-chip.count-avatar(v-if='!match.seen && match.score.percentage >= matchScoreThreshold' color="#ff5a5a" small) 
          strong NEU
    v-list-item
      v-list-item-content
        .mt-2
          v-chip(small :color="match.score.percentage >= matchScoreThreshold ? 'green' : 'grey'" dark) 
            strong {{Math.round(match.score.percentage)}}% Match
        .text-overline.mb-0.gray--text.text--lighten-2.text-truncate(v-if="match.position.company")
          | {{match.position.company.name}}
        v-list-item-title.text-h6.mb-1.mt-0.text-truncate
          | {{match.position.name}}
        //- v-list-item-subtitle match.
      v-list-item-avatar(size='80' v-if="match.position.company")
          Avatar(:src="match.position.company.logoURL" :business="true")
    v-divider.mb-2(v-if="!isDetailPage")
    v-row.my-0.py-0(v-if="!isDetailPage")
      v-col(cols="12" md="6").my-0.py-0.pr-md-0
        v-list-item(dense)
          v-list-item-icon.mr-2
            v-icon mdi-ballot
          v-list-item-subtitle {{jobTypes}}
        v-list-item(dense)
          v-list-item-icon.mr-2
            v-icon mdi-calendar
          v-list-item-subtitle {{startDate}}
      v-col(cols="12" md="6").my-0.py-0.pl-md-0
        v-list-item(dense)
          v-list-item-icon.mr-2
            v-icon mdi-map-marker
          v-list-item-subtitle {{city}}
        v-list-item(dense)
          v-list-item-icon.mr-2
            v-icon mdi-home
          v-list-item-subtitle {{areas}}
    v-divider.mt-2(v-if="!isDetailPage")
    v-card-actions.pb-4
      v-btn.ml-2(v-if="!match.archived && !isDetailPage" outlined color="accent" small rounded='' :to="{name: 'Match', params:{mid: match.id}}")
        strong.px-2 Details
      v-btn.ml-2(v-if="!match.archived && isDetailPage" outlined color="accent" small rounded=''  @click="goBack()"  exact)
        strong.px-2 Zurück
      v-spacer 
      v-btn.firstMessagBtn(v-if="!match.archived && !match.startedCommunication && !messageAlreadySent" icon color="accent")
        SendFirstMessageDialog(:selectedMatchId="match.id" :isBusiness="false" :companyId="match.position.company.id")
      v-btn(v-if="!match.archived && match.startedCommunication && !messageAlreadySent" icon color="accent"  :to="{name: 'Messages', query: {mid: match.id } }")
        v-icon mdi-message-outline
      v-btn(v-if="!match.archived && !messageAlreadySent" icon color="accent" @click="likeMatch")
        v-icon(v-if="match.liked") mdi-heart
        v-icon(v-else) mdi-heart-outline
      v-btn(v-if="!match.archived" icon color="accent" @click="archiveMatch(true)")
        v-icon mdi-delete-outline
      v-btn(v-if="match.archived" text color="accent" @click="archiveMatch(false)")
        | Wiederherstellen
    </v-avatar>
</template>

<script>
import { formatDateMonth } from '@/utilities/DateAndTime';
import SendFirstMessageDialog from '@/components/dialogs/SendFirstMessageDialog.vue';
import NotAvailableOverlay from '@/components/messages/NotAvailableOverlay.vue';
import Avatar from '@/components/elements/Avatar.vue';
import { config } from '../../../../functions/src/config';

export default {
  props: ['match', 'prevRoute'],
  components: { SendFirstMessageDialog, NotAvailableOverlay, Avatar },
  data() {
    return {
      isDetailPage:
        this.$route.name === 'Match' || this.$route.name === 'MessageMatch',
      currentUserId: this.$store.state.user.currentUser.id,
      messageAlreadySent: this.$route.name === 'MessageMatch',matchScoreThreshold: config.matchScoreThreshold,
    };
  },
  methods: {
    async archiveMatch(archive) {
      await this.$store.dispatch('candidate/markMatchSeen', this.match.id);
      await this.$store.dispatch('candidate/archiveMatch', {
        matchId: this.match.id,
        archive,
      });
    },
    async likeMatch() {
      await this.$store.dispatch('candidate/markMatchSeen', this.match.id);
      const liked = !this.match.liked;

      await this.$store.dispatch('candidate/likeMatch', {
        matchId: this.match.id,
        liked,
      });
    },
    goBack() {
      if(["Matches", "Messages"].includes(this.prevRoute.name)) {
        this.$router.back();
        return;
      }

      if (this.match.startedCommunication) {
        this.$router.push({ name: 'Messages' });
      } else {
        this.$router.push({ name: 'Matches' });
      }
    },
  },
  computed: {
    offering() {
      return this.match.position.offering;
    },
    city() {
      if (this.offering && this.offering.city && this.offering.city.name) {
        return this.offering.city.name;
      }
      return '-';
    },
    startDate() {
      if (this.offering && this.offering.startDate) {
        return formatDateMonth(this.offering.startDate);
      }
      return '-';
    },
    jobTypes() {
      if (
        this.offering &&
        this.offering.jobType &&
        this.offering.jobType.length > 0
      ) {
        return this.offering.jobType.join(', ');
      }
      return '-';
    },
    areas() {
      if (
        this.offering &&
        this.offering.areas &&
        this.offering.areas.length > 0 &&
        this.offering.areas[0].areaCategory
      ) {
        return this.offering.areas[0].areaCategory.name;
      }
      return '-';
    },
  },
};
</script>

<style lang="scss" scoped>
.firstMessagBtn:hover::before,
.firstMessagBtn::before {
  opacity: 0 !important;
}

.indicator {
  position: absolute;
  top: 32px;
  right: 32px;
}
.count-avatar{
  z-index: 6;
  position: absolute;
  top: -11px;
  right: -8px;
  strong{
    font-size: 0.8rem;
    color: #fff !important
  }
}
</style>
