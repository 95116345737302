<template lang="pug">
    v-dialog(v-model='dialog' width='600px' persistent='')
      template(v-slot:activator='{ on, attrs }')
        v-btn(icon  color="accent")
          v-icon(color='accent' v-on='on')
            | mdi-message-outline
      v-card
        div
          v-toolbar(flat color="transparent" tile)
            v-toolbar-title.text-h6.dialog-title.font-weight-bold Nachricht senden
          v-divider
        
        v-card-text.dialog-card-text.pt-6.px-4
          v-row(align="center" justify="center")
            v-col(cols='12')
              v-textarea(label='Nachricht' dense v-model="message" hide-details outlined required='')
            
        v-divider
        v-card-actions.px-4
          v-spacer
          v-btn(color='grey lighten-1' text='' @click='dialog = false')
            | Abbrechen
          
          v-btn.white--text(color='accent darken-1' depressed='' @click='sendMessage')
            | Senden
</template>
<script>
import { MatchesApi } from '@/api/modules/matches';

export default {
  props: ['selectedMatchId', 'isBusiness', 'companyId'],
  components: {},
  data() {
    return {
      dialog: false,
      message: '',
    };
  },
  computed: {},
  methods: {
    async sendMessage() {
      if (this.isBusiness) {
        await this.$store.dispatch(
          'business/markMatchSeen',
          this.selectedMatchId
        );
      } else {
        await this.$store.dispatch(
          'candidate/markMatchSeen',
          this.selectedMatchId
        );
      }
      await this.$store
        .dispatch('messages/sendMessage', {
          matchId: this.selectedMatchId,
          text: this.message,
          isFirstMessage: true,
          companyId: this.companyId,
        })
        .then(() => {
          this.message = '';

          this.$router.push({
            name: this.isBusiness ? 'MessagesBusiness' : 'Messages',
            query: { mid: this.selectedMatchId },
          });
        });
    },
  },
};
</script>
