<template lang="pug">
v-card
    NotAvailableOverlay(v-if="match.opponentRemoved" :canDelete="!match.archived"  @click="archiveMatch(true)")
    v-chip.count-avatar(v-if='!match.seen && match.score.percentage >= matchScoreThreshold' color="#ff5a5a" small) 
          strong NEU
    v-list-item
      v-list-item-content
        .mt-2
          v-chip(small :color="match.score.percentage >= matchScoreThreshold ? 'green' : 'grey'" dark) 
            strong {{Math.round(match.score.percentage)}}% Match
        .text-overline.mb-0.gray--text.text--lighten-2.text-truncate
          | {{match.position.name}}
        v-list-item-title.text-h6.mb-1.mt-0.text-truncate
          | {{candidateDisplayName}} 
        //- v-list-item-subtitle match.
      v-list-item-avatar(size='80')
        Avatar(:src="match.candidate.photoURL")
        //- v-img.grey(:src="match.candidate.photoURL" contain @load="imageError = false" @error="imageError = true")
        //-   template(v-slot:default v-if="showImagePlaceholder")
        //-     v-row.fill-height.ma-0(align='center', justify='center')
        //-       v-icon(color='white', size='60') mdi-account-circle
    
    v-card-actions.pb-4
      v-btn.ml-2(v-if="!match.archived && !isDetailPage" outlined color="accent" small rounded='' :to="{name: 'MatchBusiness', params:{mid: match.id}, query: {positionId: match.position.id}}")
        strong.px-2 Details
      v-btn.ml-2(v-if="!match.archived && isDetailPage" outlined color="accent" small rounded='' @click="goBack()" exact)
        strong.px-2 Zurück
      v-spacer 
      v-btn.firstMessagBtn(v-if="!match.archived && !match.startedCommunication  && !messageAlreadySent" icon color="accent") 
        SendFirstMessageDialog(:selectedMatchId="match.id" :isBusiness="true" :companyId="match.position.company.id")
      v-btn(v-if="!match.archived && match.startedCommunication && !messageAlreadySent" icon color="accent" :to="{name: 'MessagesBusiness', query: {mid: match.id, positionId: match.position.id } }")
        v-icon mdi-message-outline
      v-btn(v-if="!match.archived && !messageAlreadySent" icon color="accent" @click="likeMatch")
        v-icon(v-if="match.liked") mdi-heart
        v-icon(v-else) mdi-heart-outline
      v-btn(v-if="!match.archived" icon color="accent" @click="archiveMatch(true)")
        v-icon mdi-delete-outline
      v-btn(v-if="match.archived" text color="accent" @click="archiveMatch(false)")
        | Wiederherstellen
</template>

<script>
import SendFirstMessageDialog from '@/components/dialogs/SendFirstMessageDialog.vue';
import NotAvailableOverlay from '@/components/messages/NotAvailableOverlay.vue';
import Avatar from '@/components/elements/Avatar.vue';
import { config } from '../../../../functions/src/config';

export default {
  props: ['match', 'prevRoute'],
  components: { SendFirstMessageDialog, NotAvailableOverlay, Avatar },
  data() {
    return {
      isDetailPage:
        this.$route.name === 'MatchBusiness' ||
        this.$route.name === 'MessageMatchBusiness',
      currentUserId: this.$store.state.user.currentUser.id,
      messageAlreadySent: this.$route.name === 'MessageMatchBusiness',
      matchScoreThreshold: config.matchScoreThreshold,
    };
  },
  computed: {
    candidateDisplayName() {
      if (this.match.candidate.anonymous) {
        return 'Anonym';
      } else {
        return `${this.match.candidate.firstName} ${this.match.candidate.lastName}`;
      }
    },
  },
  methods: {
    async archiveMatch(archive) {
      await this.$store.dispatch('business/markMatchSeen', this.match.id);
      await this.$store.dispatch('business/archiveMatch', {
        matchId: this.match.id,
        archive,
      });
    },
    async likeMatch() {
      await this.$store.dispatch('business/markMatchSeen', this.match.id);
      const liked = !this.match.liked;
      await this.$store.dispatch('business/likeMatch', {
        matchId: this.match.id,
        liked,
      });
    },
    goBack() {
      if (
        ['MatchesBusiness', 'MessagesBusiness'].includes(this.prevRoute.name)
      ) {
        this.$router.back();
        return;
      }

      if (this.match.startedCommunication) {
        this.$router.push({ name: 'MessagesBusiness' });
      } else {
        this.$router.push({ name: 'MatchesBusiness' });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.firstMessagBtn:hover::before,
.firstMessagBtn::before {
  opacity: 0 !important;
}
.count-avatar {
  z-index: 6;
  position: absolute;
  top: -11px;
  right: -8px;
  strong {
    font-size: 0.8rem;
    color: #fff !important;
  }
}
</style>
