<template lang="pug">
.Matches.pb-12
  .div(v-if="matches && currentPosition")
    v-row
      v-col 
      v-col.pt-0(cols="12" md="4" lg="2")
        v-select(:items="selectOptions" outlined dense v-model="selectedOption" @change="optionChanged")
    v-row.px-1(v-if="generatingMatches")
      v-col.text-center.mt-12(cols="12")
        span() Matches werden generiert
      v-col.text-center(cols="12")
        v-progress-circular(indeterminate)
    v-row.px-1(v-else-if="loadingFirstPage")
      v-col.pa-2(v-for="index in 3" cols="12" sm="6" lg="4")
        v-skeleton-loader(type="article, actions")
    v-row.px-1.pb-12.pb-md-4(v-else-if="matches && matches.length > 0")
      v-col.pa-2(v-for="(match, index) in matches" :key="index" cols="12" sm="6" lg="4")
        MatchCard(:match="match")
    v-row.pt-0.pb-12.pb-md-4(v-else)
      v-col.text-center.mt-12(v-if="!loadingFirstPage")
        span(v-if="selectedOption == 'Matches'") Sie haben noch keine Matches
        span(v-if="selectedOption == 'Likes'") Sie haben noch keine Matches favorisiert
        span(v-if="selectedOption == 'Archive'" ) Ihr Archive ist leer
    v-row.pt-0.pb-12.pb-md-4(v-if="loadingMorePages")
      v-col.text-center.mt-12()
        v-progress-circular(indeterminate)
  EmptyCardPosition(v-else text="Noch keine Stelle hinzugefügt")
</template>
<script>
import Vue from 'vue';
import MatchCard from '@/components/business/matches/MatchCard.vue';
import EmptyCardPosition from '@/components/cards/EmptyCardPosition';
import { debounce } from 'debounce';

export default Vue.extend({
  components: { MatchCard, EmptyCardPosition },
  data() {
    return {
      // selectedOption: 'Matches',
      selectOptions: ['Matches', 'Likes', 'Archive'],
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    this.getNextMatches();
    if (this.prevRoute?.name !== 'MatchBusiness' || this.matches.length <= 1) {
      this.reloadMatches();
    }
  },
  methods: {
    optionChanged(section) {
      this.$store.dispatch('business/fetchMatches', {
        section: section,
        reset: true,
      });
    },
    onScroll() {
      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight ===
        document.documentElement.offsetHeight;

      if (bottomOfWindow) {
        this.$store.dispatch('business/fetchMatches', {
          section: this.selectedOption,
          reset: false,
        });
      }
    },
    getNextMatches() {
      this.getNextMatches = debounce(this.onScroll, 200);
      window.addEventListener('scroll', this.onScroll);
    },
    reloadMatches() {
      this.$store.dispatch('business/fetchMatches', {
        section: this.selectedOption,
        reset: true,
      });
    },
  },
  beforeRouteEnter(_to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  beforeRouteLeave(_to, _from, next) {
    window.removeEventListener('scroll', this.onScroll);
    next();
  },
  computed: {
    selectedOption: {
      get() {
        return this.$store.state.business.selectedMatchesSection;
      },
      set(newValue) {
        return this.$store.commit(
          'business/setSelectedMatchesSection',
          newValue
        );
      },
    },
    loadingFirstPage() {
      return this.$store.state.business.loading && this.matches.length === 0;
    },
    loadingMorePages() {
      return this.$store.state.business.loading && this.matches.length !== 0;
    },
    matches() {
      return this.$store.getters['business/matchesForSection'](
        this.selectedOption
      );
    },
    currentPosition() {
      return this.$store.state.business.positions.find(
        (position) => position.id === this.selectedPositionId
      );
    },
    selectedPositionId() {
      return this.$store.state.business.selectedPositionId;
    },
    generatingMatches() {
      return this.$store.state.business.generatingMatches;
    },
  },
  watch: {
    selectedPositionId(id) {
      if (!id) return;
      this.reloadMatches();
    },
    generatingMatches: function (updated, old) {
      if (old && !updated) {
        this.reloadMatches();
      }
    },
  },
});
</script>

<style lang="sass">
@import "@/assets/style/main"
// body
//   min-width: 560px
</style>
