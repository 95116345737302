<template lang="pug">
    v-overlay.grayscale.text-center(:absolute="true" :overlay="false" color="grey lighten-2" :opacity="0.95")
        v-alert(color="grey darken-1" outlined) Match nicht mehr vorhanden
        v-btn.text-center(v-if="canDelete" text color="grey darken-2" @click.stop="deleteMatch") Match entfernen
</template>
<script>
export default {
  props: ['canDelete'],
  methods: {
    deleteMatch() {
      this.$emit('click');
    },
  },
};
</script>
<style lang="sass"></style>
